import React, {Suspense, lazy} from 'react';
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import { Bars } from 'react-loader-spinner';
const Home =  lazy(() => import('./pages/presale-home/home'));
const Legal = lazy(() => import('./pages/legal/legal'))
function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div className='page-load'><Bars color='white'/></div>}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/legal' element={<Legal />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
